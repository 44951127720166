.app-date-input {
	position: relative;
	width: 100%;
	min-width: 150px;
	display: flex;

	&--disabled {
		background-color: #f8f8f8;
	}

	&__label {
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
		margin-bottom: 10px;
	}

	&__required {
		color: $c-red;
		line-height: 0;
	}

	&__error {
		width: 100%;
		margin: 3px 0 0;
		color: $c-red;
		font-weight: $text-weight-normal;
		font-size: $text-size-xs;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	
}

.react-datepicker {
	z-index: 1;

	&-wrapper {
		border: 0 !important;
		border-bottom: 1px solid $c-disabled !important;
	}

	&__input-container {
		width: 100%;

		input {
			font: inherit;
			font-weight: $text-weight-normal;
			letter-spacing: inherit;
			color: $c-black;
			border: 0;
			box-sizing: content-box;
			background: none;
			height: 1.4375em;
			margin-bottom: 10px;
			-webkit-tap-highlight-color: transparent;
			display: block;
			min-width: 0;
			width: 100%;
			-webkit-animation-name: mui-auto-fill-cancel ;
			animation-name: mui-auto-fill-cancel;
			-webkit-animation-duration: 10ms;
			animation-duration: 10ms;
		}

		::placeholder {
			color: #e6e6e6;
		}
	}
}