.ezc-app {
	//
	.app-header {
		display: none;

		.header {
			display: none;
		}
	}
}

.app-header {
	position: relative;
	width: 100%;
	padding: 10px 0;
	background-color: rgb(3, 149, 150);
	background: linear-gradient(157deg, rgba(3, 149, 150, 1) 0%, rgba(9, 166, 148, 1) 40%, rgba(22, 202, 144, 1) 100%);

	.header {
		width: 100%;
		max-width: 1024px;
		margin: 0 auto;

		@include max-width($scr-lg) {
			padding: 0 20px;
		}

		&__image {
			width: 180px;
			display: block;

			img {
				width: 100%;
			}
		}

		&__circle {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 200px;

			img {
				width: 100%;
			}
		}
	}
}
