.app-search-input {
	min-height: 46px;
	display: flex;
	flex-direction: row;

	.search-input {
		width: 100%;
		max-width: 768px;

		.app-icon {
			width: 20px;
			height: 20px;
		}

		.app-input {
			.MuiOutlinedInput-notchedOutline {
				border-bottom: 0 !important;
			}
		}
	}

	.advance-search {
		min-height: 43px;
		margin: 0 0 10px;
		gap: 5px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.app-icon {
			cursor: pointer;

			&__i {
				width: 16px;
				height: 16px;
			}
		}

		&__text {
			padding: 0 10px;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			cursor: pointer;
		}

		&__value {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		&__remove {
			border: 0;
			padding: 0 4px;
			cursor: pointer;
			pointer-events: auto;
			background-color: transparent;
			display: flex;
			flex-shrink: 0;

			svg {
				width: 8px;
				height: 8px;
			}
		}

		&__pill {
			padding: 4px 8px;
			border-radius: 100px;
			background-color: transparent;
			border: 1px solid $c-dark-grey;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&__button-container {
		margin-left: auto;
		gap: 10px;
		display: flex;
		align-items: stretch;
		justify-content: center;
	}

	.app-button {
		width: inherit;
		min-width: 130px;
		padding: 5px 15px;
		flex-shrink: 0;
		flex-direction: row-reverse;

		&__label {
			margin: 0 5px 0 0;
			white-space: nowrap;
			font-weight: $text-weight-normal;
		}

		.app-icon {
			width: 12.5px;
			height: 12.5px;
		}
	}
}

.app-search-menu {
	margin: 0 15px;
	pointer-events: none;

	.MuiBackdrop-root {
		pointer-events: auto;
	}

	.MuiPaper-root {
		width: 100%;
		max-width: 640px;
		padding: 20px;
		border-radius: 10px;
		pointer-events: auto;

		@include max-width($scr-sm) {
			max-width: 270px;
			left: 20px !important;
		}
	}
}
