.app-accordion-container {
	//
	&--active {
		.card-additional-driver {
			.driver-main {
				&__body-input {
					display: flex !important;
					flex-wrap: wrap !important;
					flex-direction: row !important;
					gap: 20px 35px !important;

					.app-input,
					.app-button-input,
					.app-select-input,
					.app-mobile-input,
					.app-select-status,
					.app-calendar-input,
					.app-masking-input {
						@include min-width($scr-sm) {
							width: calc(50% - 17.5px);
						}
					}
				}
			}
		}
	}

	.main {
		width: 100%;
		border-radius: 8px;
		background-color: $c-white;
		box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);

		&__header {
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include max-width($scr-xs) {
				padding: 15px;
			}

			&:last-child {
				text-align: right;
			}
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
		}

		&__box {
			padding: 10px;
		}

		&__title {
			margin: 0;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__divider {
			border: 1px dashed $c-disabled;
		}

		&__description {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			font-weight: $text-weight-regular;
		}

		&__footer {
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			@include max-width($scr-xs) {
				padding: 15px;
			}
		}

		&__arrow {
			transform: rotate(180deg);

			&--expand {
				transform: rotate(0);
			}
		}

		&__button-wrapper {
			//
			.app-button {
				width: auto;
				padding: 0;
				gap: 10px;
				border: 0;
				background-color: transparent;

				&__label {
					color: $c-red;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
				}
			}
		}
	}
}
