.app-card-status {
	width: 100%;

	.card-status {
		//
		&__body {
			max-width: 500px;
			padding: 120px 15px;
			margin: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&__title {
			margin: 15px 0 0 0;
			color: $c-primary;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
			text-align: center;
		}

		&__network-body {
			max-width: 500px;
			padding: 220px 15px 120px;
			margin: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&__network-title {
			margin: 15px 0 0 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
			text-align: center;

			@include max-width($scr-xs) {
				font-size: $text-size-l;
			}
		}

		&__sub-title {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-body;
			font-weight: $text-weight-regular;
			text-align: center;
		}

		&__note {
			margin: 10px 0 0;
			color: $c-dark-grey;
			font-size: $text-size-body;
			font-weight: $text-weight-regular;
			text-align: center;
		}

		&__email {
			color: $c-primary;
			text-decoration: none;
		}

		&__error {
			margin: 15px 0 0 0;
			color: $c-red;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
			text-align: center;
		}

		&__description {
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
		}

		&__sub-description {
			padding: 0 29px;
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
		}

		&__calendar-icon {
			width: 150px;
			height: 98px;
		}

		&__wrong-icon {
			width: 150px;
			height: 180px;
		}

		&__fail-icon {
			width: 240px;
			height: 240px;
		}

		&__applicant-icon {
			width: 180px;
			height: 180px;
		}

		&__container {
			padding: 24px;
			margin: 20px 0 20px 0;
			border-radius: 8px;
			background-color: rgba(1, 136, 151, 0.05);
		}

		&__wrapper {
			gap: 5px;
			display: flex;
		}

		&__button-container {
			width: 100%;
			margin: 20px 0 0 0;
			display: flex;
			justify-content: center;

			.app-button {
				//
				margin-right: 20px;
				&--outline {
					//
					.app-button__label {
						color: $c-teal;
					}
				}
			}
		}
	}
}
