.app-card-info {
	//
	.card-info {
		padding: 20px;
		box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		background-color: $c-white;
		border-radius: 8px;

		&__title {
			margin: 0;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}
	}

	.list {
		margin: 0;
		padding: 0;
		list-style-type: none;

		&__item {
			margin: 10px 0;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		&__text {
			margin: 0;
			text-align: left;
			color: $c-dark-grey;
			font-size: $text-size-s;
			word-wrap: break-word;
			word-break: break-word;
			flex: 2;

			&--value {
				color: $c-black;
				text-align: right;
				flex: 3;
			}
		}
	}
}
