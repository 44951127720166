.app-market-card {
	width: calc(50% - 20px);
	min-width: 250px;
	margin-bottom: 15px;

	@include max-width($scr-md) {
		width: 100%;
	}

	.market-card {
		background-color: transparent;
		display: flex;

		&__radio {
			width: 20px;
			height: 20px;
			margin-bottom: auto;
			padding: 3px;
			cursor: pointer;
			background-color: transparent;
			border: 1px solid $c-light-grey;
			border-radius: 100%;

			&--active {
				//
				&:before {
					width: 100%;
					height: 100%;
					content: "";
					background-color: $c-teal;
					border-radius: 100%;
					display: block;
				}
			}
		}

		&__container {
			width: 100%;
			min-width: 0;
			padding: 10px;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
			border-radius: 8px;
		}

		&__box {
			padding: 10px;
		}

		&__body {
			text-align: right;
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
		}

		&__description {
			color: $c-dark-grey;
			word-break: break-word;
			font-size: $text-size-xxs;
			font-weight: $text-weight-regular;
		}
	}

	.body {
		padding: 20px;
		display: flex;
		justify-content: space-between;

		&__wrapper {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__text {
			margin: 0;
			color: $c-dark-grey;
			word-break: break-all;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
		}

		&__value {
			margin: 0;
			color: $c-black;
			word-break: break-all;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
		}

		&__disabled {
			margin: 0;
			color: $c-light-grey;
			word-break: break-all;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
		}
	}
}
