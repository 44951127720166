.app-card {
	width: 50%;
	min-width: 250px;
	margin-bottom: 15px;

	@include max-width($scr-md) {
		width: 100%;
	}

	.main-content {
		background-color: transparent;
		display: flex;

		&__radio {
			width: 20px;
			height: 20px;
			padding: 3px;
			margin-bottom: auto;
			border-radius: 100%;
			background-color: transparent;
			border: 1px solid $c-light-grey;
			cursor: pointer;

			&--active {
				//
				&:before {
					width: 100%;
					height: 100%;
					content: "";
					background-color: $c-teal;
					border-radius: 100%;
					display: block;
				}
			}
		}

		&__container {
			width: 100%;
			min-width: 0;
			padding: 10px;
			border-radius: 8px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		}

		&__box {
			padding: 10px;
		}

		&__divider {
			width: 100%;
			height: 1px;
			background-color: $c-disabled;
		}

		&__text {
			color: $c-dark-grey;
			font-size: $text-size-xxs;
			font-weight: $text-weight-regular;
		}

		&__title {
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__body {
			text-align: right;
		}
	}

	.header {
		display: flex;
		justify-content: space-between;

		&__company {
			padding: 0 5px 0 0;
			display: flex;
			align-items: center;
		}

		&__image {
			width: 30px;
			height: 30px;
			border-radius: 70px;
			margin: 0 5px 0 0;
			border: 4px solid $c-white;
			box-shadow: 2px 4px 8px 0px rgba(37, 37, 37, 0.08);
			flex-shrink: 0;

			img {
				width: 100%;
			}
		}

		&__text {
			color: $c-dark-grey;
			font-size: $text-size-xxs;
			font-weight: $text-weight-regular;
		}

		&__title {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			word-break: break-word;
		}

		&__body {
			text-align: right;
			display: flex;
			flex-direction: column;
		}
	}

	.body {
		padding: 10px 0;
		display: flex;
		justify-content: space-between;

		&__content {
			flex: 2;
			display: flex;
			justify-content: space-between;
		}

		&__text {
			margin-top: auto;
			color: $c-dark-grey;
			word-break: break-all;
			font-size: $text-size-xs;
		}

		&__title {
			text-align: right;
			color: $c-dark-grey;
			word-break: break-all;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__sum-insured {
			text-align: right;
			flex: 1;
		}
	}

	.footer {
		padding: 15px 0 0;

		.app-button {
			padding: 0;
			max-width: 100%;
			background-color: transparent;

			&__label {
				color: $c-teal;
				font-size: $text-size-xs;
			}
		}
	}
}

.app-detail-menu {
	width: 100%;

	.MuiList-root {
		padding: 16px 0 16px 16px;

		@include max-width($scr-md) {
			padding: 16px;
		}
	}

	.MuiBackdrop-root {
		pointer-events: auto;
	}

	.MuiPaper-root {
		width: 100%;
		max-width: 1024px;
		padding-right: 16px;
		box-shadow: none;
		background-color: transparent;

		@include max-width($scr-md) {
			left: 0 !important;
		}
	}

	.detail-menu {
		width: 100%;
		min-width: 300px;
		max-width: calc(100% - 350px);
		margin-left: auto;
		border-radius: 10px;
		box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);
		background-color: $c-white;
		pointer-events: auto;
		display: flex;
		flex-direction: column;

		@include max-width($scr-md) {
			max-width: 100%;
			margin-left: 0;
		}

		&__container {
			width: 100%;
			min-width: 0;
			padding: 20px;
		}

		.header {
			margin: 20px 0;
			display: flex;
			justify-content: space-between;

			&__company {
				padding: 0 5px 0 0;
				display: flex;
				align-items: center;
			}

			&__image {
				width: 30px;
				height: 30px;
				border-radius: 70px;
				margin: 0 5px 0 0;
				border: 4px solid $c-white;
				box-shadow: 2px 4px 8px 0px rgba(37, 37, 37, 0.08);
				flex-shrink: 0;

				img {
					width: 100%;
				}
			}

			&__text {
				color: $c-dark-grey;
				font-size: $text-size-s;
				font-weight: $text-weight-regular;
			}

			&__title {
				margin: 0;
				color: $c-black;
				font-size: $text-size-body;
				font-weight: $text-weight-semibold;
			}
		}

		.body {
			padding: 10px 0;

			&__title {
				color: $c-dark-grey;
				word-break: break-word;
				font-size: $text-size-s;
				font-weight: $text-weight-regular;
				font-style: italic;
			}

			&__list {
				//
			}

			&__item {
				padding: 5px 0;
				list-style: disc;
			}

			&__text {
				margin: 0;
				color: $c-dark-grey;
				word-break: break-word;
				font-size: $text-size-s;
			}
		}

		.footer {
			padding: 10px 0;
			display: flex;
			justify-content: space-between;

			@include max-width($scr-xs) {
				flex-direction: column;
				gap: 10px;
			}

			&__content {
				gap: 20px;
				display: flex;
				justify-content: space-between;
			}

			&__upper-content {
				gap: 20px;
				display: flex;
			}

			&__sum-content {
				display: flex;
			}

			&__text {
				color: $c-dark-grey;
				word-break: break-all;
				font-size: $text-size-s;

				@include max-width($scr-xs) {
					font-size: $text-size-xs;
				}
			}

			&__title {
				margin-top: auto;
				color: $c-dark-grey;
				word-break: break-all;
				font-size: $text-size-s;
				font-weight: $text-weight-normal;

				@include max-width($scr-xs) {
					font-size: $text-size-xs;
				}
			}

			&__sub-title {
				text-align: right;
				color: $c-dark-grey;
				word-break: break-all;
				font-size: $text-size-body;
				font-weight: $text-weight-semibold;

				@include max-width($scr-xs) {
					font-size: $text-size-s;
				}
			}

			&__wrapper {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}

			&__premium-amount {
				text-align: right;
			}
		}

		&__button-container {
			background-color: $c-teal;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;

			.app-button {
				margin: auto;
			}
		}

		&__divider {
			width: 100%;
			height: 1px;
			background-color: $c-disabled;
		}

		&__remove {
			border: 0;
			background-color: transparent;
			cursor: pointer;
			pointer-events: auto;
			flex-shrink: 0;

			svg {
				width: 16px;
				height: 16px;
			}
		}
	}
}

.download-menu {
	//
	&__text {
		min-width: 100px;
		text-decoration: none;
		font-size: $text-size-s;
		font-weight: $text-weight-regular;
	}
}
