.app-card-container {
	//
	.main {
		width: 100%;
		border-radius: 8px;
		background-color: $c-white;
		box-shadow: 0px 2px 16px 0px rgba(224, 229, 234, 0.6);

		&__header {
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include max-width($scr-xs) {
				padding: 15px;
			}

			&:last-child {
				text-align: right;
			}
		}

		&__wrapper {
			display: flex;
			flex-direction: column;
		}

		&__box {
			padding: 10px;
		}

		&__title {
			margin: 0;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__divider {
			border: 1px dashed $c-disabled;
		}

		&__description {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-xs;
			font-weight: $text-weight-regular;
		}

		&__footer {
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			@include max-width($scr-xs) {
				padding: 15px;
			}
		}

		.search {
			display: flex;
			flex-direction: column;

			&__inputs {
				gap: 15px;
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;

				.app-input,
				.app-calendar-input,
				.app-select-input {
					width: calc(50% - 7.5px);

					@include max-width($scr-sm) {
						width: 100%;
					}
				}
			}

			&__button-container {
				padding: 20px 0 0;
				display: flex;
				align-items: center;
				justify-content: center;

				.app-button {
					width: auto;
					margin: 0 10px;
					padding: 10px 20px;

					&--outline {
						border: 0;

						.app-button__label {
							color: $c-primary;
						}
					}
				}
			}
		}

		.MuiSwitch-root {
			width: 28px;
			height: 18px;
			padding: 0;
			margin: 5px 0;
			margin-left: auto;
			border-radius: 100px;

			.MuiSwitch-switchBase {
				padding: 3px;
			}

			.Mui-checked {
				color: $c-teal;
				transform: translateX(10px);

				+ .MuiSwitch-track {
					opacity: 1;
					background-color: $c-teal;
				}

				.MuiSwitch-thumb {
					&:before {
						content: "";
						position: absolute;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						background-size: 8px;
						background-position: center;
						background-repeat: no-repeat;
						background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEwIDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDQuNTA1NjJMMy4zNDMxNSA3TDkgMSIgc3Ryb2tlPSIjMDE4ODk3IiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
					}
				}
			}

			.MuiSwitch-thumb {
				width: 12px;
				height: 12px;
				color: $c-white;
			}
		}

		&__button-wrapper {
			gap: 8px;
			cursor: pointer;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.app-button {
				width: auto;
				padding: 0;
				border: 0;
				background-color: transparent;

				&__label {
					color: $c-red;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
				}
			}
		}
	}
}
